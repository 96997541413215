/*! Basic Scripts 2020-04-20 */

/*----------------------------------------
  Fixed Header & Shrink
----------------------------------------*/
$(document).ready(function(){
  $('#header').scrollToFixed();
});

$(document).on("scroll", function(){
  if ($(document).scrollTop() > 100){
    $("header").addClass("shrink");
  } else {
    $("header").removeClass("shrink");
  }
});


/*----------------------------------------
  Fixed Footer
----------------------------------------*/
$(document).ready(function(){
  function fullmainwindow(){
    var intMinMainHeight = jQuery(window).height() - $('#header').outerHeight() - $('#footer').outerHeight();
    if ($('#main').outerHeight() < intMinMainHeight) {
      $("#main").css('minHeight', intMinMainHeight);
    }
  }
  fullmainwindow();
  // Run the function in case of window resize
  jQuery(window).resize(function() {
    fullmainwindow();
  });
});


/*----------------------------------------
  Form width placeholder
----------------------------------------*/
/*
$("form :input").each(function(index, elem) {
  var eId = $(elem).attr("id");
  var label = null;
  if (eId && (label = $(elem).parents("form").find("label[for=" + eId + "]")).length == 1) {
    if (!$(elem).hasClass("captcha")) {
      var placeholder = $(label).text();
      var tmp_placeholder = placeholder.replace("Pflichtfeld", "");
      var new_placeholder = tmp_placeholder.trim();
      $(elem).attr("placeholder", new_placeholder);
      $(label).addClass('hide');
    }
  }
});
$("form :input").focus(function() {
  $(this).parent(".widget").each(function() {
    $("label", this).removeClass("hide");
    $("label", this).addClass("active");
  });
}).blur(function() {
  if ($(this).val() === "") {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
      $("label", this).addClass("hide");
    });
  } else {
    $(this).parent(".widget").each(function() {
      $("label", this).removeClass("active");
    });
  }
});
*/

/*----------------------------------------
  Top-Link
----------------------------------------*/
$(document).ready(function() {
  $('div.toplink').hide();
  $(window).scroll(function(){
    var value = 200;
    var scrolling = $(window).scrollTop();
    if (scrolling > value) {
      $('div.toplink').fadeIn();
    } else {
      $('div.toplink').fadeOut();
    }
  });
  $('div.toplink').click(function(){
    $('html, body').animate({scrollTop:'0px'}, 800);
    return false;
  });
});


/*----------------------------------------
  SIDR-Navigation - Toogler
----------------------------------------*/
$(document).ready(function() {
  $('.navbar-toggle-on').sidr({
    name: 'navbar-toggle',
    source: '.navbar-collapse',
    side: 'left',
    speed: 500,
    displace: false,
    onOpen: function () {
      document.getElementById("overlay").style.display = "block";
    },
    onClose: function () {
      document.getElementById("overlay").style.display = "none";
    }
  });
  $( window ).resize(function () {
    $.sidr('close', 'navbar-toggle');
  });
  $(".sidr-class-navbar-toggle-off").click(function() {
    $.sidr('close', 'navbar-toggle');
  });
});


/*----------------------------------------
  Sidr-Navigation - Vertical-Menue
----------------------------------------*/
$(document).ready(function() {
  $(".sidr-class-mod_navigation li").each(function(i) {
    if ($(".sidr-class-mod_navigation li").eq(i).hasClass("sidr-class-trail")) {
      $(".sidr-class-mod_navigation li").eq(i).addClass("sidr-class-nav--open");
    }
  });
  $(".sidr-class-mod_navigation a").click(function() {
    var link = $(this);
    var closest_ul = link.closest("ul");
    var parallel_active_links = closest_ul.find(".sidr-class-nav--open");
    var closest_li = link.closest("li");
    var link_status = closest_li.hasClass("sidr-class-nav--open");
    var count = 0;

    closest_ul.find("ul").slideUp(function() {
      if (++count == closest_ul.find("ul").length)
        parallel_active_links.removeClass("sidr-class-nav--open");
    });

    if (!link_status) {
      closest_li.children("ul").slideDown();
      closest_li.addClass("sidr-class-nav--open");
    }

  });
});


/*----------------------------------------
  Onepager-Toggler
----------------------------------------*/
$(document).ready(function() {
  $('.onepagebar-toggler').click(function() {
    $(".onepagebar-toggler").toggleClass("onepagebar-toggler--active");
    if ($(".onepagebar-toggler").hasClass("onepagebar-toggler--active")) {
      document.getElementById("onepagebar-nav").style.height = "100%";
    } else {
      document.getElementById("onepagebar-nav").style.height = "0%";
    }
  });
  $('.onepagebar-overlay-content li a').click(function() {
    $(".onepagebar-toggler").toggleClass("onepagebar-toggler--active");
    document.getElementById("onepagebar-nav").style.height = "0%";
  });
});


/*----------------------------------------
  Onepager-Scrolling
----------------------------------------*/
$(document).ready(function() {
  var el = $('a[href*="#"]').not('[href="#"]').not('[href="#0"]');
  var scrollSpeed = 800;
  var uri = window.location.href.split("#")[0];

  el.click(function(event) {
    // check if On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 100
        }, scrollSpeed);
      }
    }
  });

  // set nav active when scrolling
  var navActive = function(section) {
    var $el = $('.onepage_navigation__list');

    $el.find('li.active').removeClass('active');
    $el.each(function() {
      $(this).find('a[data-onepagelink="' + section + '"]').parent().addClass('active');
    });
  };

  var navigationSection = function() {
    var $section = $('.mod_article');

    $section.waypoint(function(direction) {

      if (direction === 'down') {
        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);

          // change url to active link
          if (typeof(window.history.pushState) == 'function') {
            history.pushState("", "", uri + '#' + this.element.id);
          }
        }
      }
    }, {
      offset: '50%'
    });

    $section.waypoint(function(direction) {
      if (direction === 'up') {
        var previousWaypoint = this.previous();

        if ($(this.element).hasClass('onepage_article')) {
          // change url to active link
          navActive(this.element.id);
          history.pushState("", "", uri + '#' + this.element.id);
        } else {
          navActive(previousWaypoint.element.id);
          history.pushState("", "", uri + '#' + previousWaypoint.element.id);
        }
      }
    }, {
      offset: function() {
        return (this.context.innerHeight() / 2) - this.element.offsetHeight; // offset 50% from bottom
      },
      group: $section
    });
  };

  $(function() {
    navigationSection();
  });

});


/*----------------------------------------
  Tab - Accordion - Modul-Container
----------------------------------------*/
// Tab
$(".tab_content").hide();
$(".tab_content:first").show();
/* tab in tab mode */
$("ul.nav_tab li").click(function() {
  $(".tab_content").hide();
  var activeTab = $(this).attr("rel");
  $("#" + activeTab).fadeIn();
  $("ul.nav_tab li").removeClass("active");
  $(this).addClass("active");
  $(".tab_heading").removeClass("d_active");
  $(".tab_heading[rel^='" + activeTab + "']").addClass("d_active");
});
/* tab in accordion mode */
$(".tab_heading").click(function() {
  $(".tab_content").hide();
  var d_activeTab = $(this).attr("rel");
  $("#" + d_activeTab).fadeIn();
  $(".tab_heading").removeClass("d_active");
  $(this).addClass("d_active");
  $("ul.nav_tab li").removeClass("active");
  $("ul.nav_tab li[rel^='" + d_activeTab + "']").addClass("active");
});

// Accordion
$(".accordion_content").hide();
$(".accordion_content:first").show();
$(".accordion_heading").click(function() {
  $(".accordion_content").hide();
  var d_activeAccordion = $(this).attr("rel");
  if ($(this).hasClass("d_active")) {
    $(".accordion_heading").removeClass("d_active");
  } else {
    $("#" + d_activeAccordion).fadeIn();
    $(".accordion_heading").removeClass("d_active");
    $(this).addClass("d_active");
  }
});

// Modul-Container
$(".modul_content").hide();
$(".modul_content:first").show();
$("ul.nav_modul li").click(function() {
  $(".modul_content").hide();
  var activeTab = $(this).attr("rel");
  $("#" + activeTab).fadeIn();
  $("ul.nav_modul li").removeClass("active");
  $(this).addClass("active");
});
